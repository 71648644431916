<template>
  <card card-body-classes="table-full-width">
    <form @submit.prevent="submit">
      <el-row>
        <el-col>
          <base-input :required="true" label="Título do relatório" v-model="form.title"></base-input>
        </el-col>
        <el-col>
          <base-input :required="true" label="Descrição do relatório" v-model="form.description"></base-input>
        </el-col>
        <el-col>
          <base-input :required="true" label="Código do Dashboard" v-model="form.dashboard_id"></base-input>
        </el-col>
        <el-col>
          <label for="unity_id">Habilitar para a função</label>
          <select @change="filterUser" id="unity_id" v-model="form.role" class="form-control custom-select">
            <option value=0>
              Todas
            </option>
            <option value=1>
              Gerador
            </option>
            <option value=7>
              Coletor
            </option>
            <option value=6>
              Entidade
            </option>
            <option value=9>
              Prefeitura
            </option>
          </select>
        </el-col>
        <el-col>
          <label for="unity_id">Usuários habilitados</label>
          <treeselect placeholder="Selecione os usuários" v-model="form.users" :multiple="true" :options="usersFiltered" />
        </el-col>
        <el-col class="mt-3" v-if="!loading">
          <label class="mr-3 mb-0">Status</label>
          <el-radio-group v-model="form.enabled">
            <el-radio class="mb-0" :label="true">Ativo</el-radio>
            <el-radio class="mb-0" :label="false">Inativo</el-radio>
          </el-radio-group>
        </el-col>
        <!-- botoes -->
        <el-col class="mt-3">
          <base-button @click.prevent.stop="$router.go(-1)" type="danger">Cancelar</base-button>
          <base-button native-type="submit" type="success" class="ml-3">Salvar</base-button>
        </el-col>
      </el-row>
    </form>
  </card>
</template>

<script>
import swal from 'sweetalert2'
import axios from 'axios'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: 'form',
  components: {
    Treeselect
  },
  data () {
    return {
      form: {
        title: '',
        description: '',
        dashboard_id: ''
      },
      loading: true,
      users: [],
      usersFiltered: []
    }
  },
  async mounted () {
    const response = await axios.get('/reports/users', {
      headers: {
        Authorization: this.$jwt.getAccessToken()
      }
    })
    this.users = response.data.data

    if (this.$route.params.id === '0') {
      this.form = {
        id: 0,
        title: '',
        description: '',
        dashboard_id: '',
        role: 0,
        enabled: true
      }
    } else {
      const response = await axios.get('/reports/' + this.$route.params.id, {
        headers: {
          Authorization: this.$jwt.getAccessToken()
        }
      })
      this.form = response.data.data
    }

    const roleDefault = this.form.role
    const userFiltered = []
    this.users.forEach(function (value, index) {
      if (parseInt(roleDefault) === 0) {
        userFiltered.push(value)
      } else {
        if (parseInt(roleDefault) === parseInt(value.role_id)) {
          userFiltered.push(value)
        }
      }
    })

    this.usersFiltered = userFiltered
  },
  methods: {
    filterUser () {
      const roleDefault = this.form.role
      const userFiltered = []
      this.users.forEach(function (value, index) {
        if (parseInt(roleDefault) === 0) {
          userFiltered.push(value)
        } else {
          if (parseInt(roleDefault) === parseInt(value.role_id)) {
            userFiltered.push(value)
          }
        }
      })

      this.usersFiltered = userFiltered
    },
    validate () {
      const form = this.form
      if (!form.title || !form.title.trim()) return false
      if (!form.description || !form.description.trim()) return false
      if (!form.dashboard_id || !form.dashboard_id.toString().trim()) return false
      return true
    },
    async submit () {
      if (this.validate()) {
        const response = await axios.post('/reports', this.form, {
          headers: {
            Authorization: this.$jwt.getAccessToken(),
            'Content-Type': 'application/json'
          }
        })

        if (response && response.status === 200) {
          await swal.fire({
            title: 'Adicionado!',
            text: 'Relatório salvo com sucesso.',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            this.$router.push('/reports')
          })
        } else {
          await swal.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            denyButtonText: 'Ok',
            showDenyButton: true,
            showConfirmButton: false
          })
        }
      } else {
        await swal.fire({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
      }
    }
  }
}
</script>

<style scoped>
.emailPreview {
  border-radius: 0.4rem;
  padding: 10px 18px 10px 18px;
}

.emailPreview,
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-color: rgba(29, 37, 59, 0.5) !important;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}</style>
